
import i18n from '@/vueI18n';
import { mapGetters, mapActions } from 'vuex';
import FsLightbox from 'fslightbox-vue';
import RestaurantImageCaption from '@/modules/restaurant/components/restaurant-image-caption.vue';

export default {
  name: 'app-restaurant-view-page',
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      record: 'restaurant/view/record',
      loading: 'restaurant/view/loading',
    }),
    isRTL() {
      return i18n.locale == 'ar';
    },
    evenNumbers() {
      if (this.record) {
        return this.record.images
          .map((_, i) => i)
          .filter((i) => i % 3 === 0);
      }
      return [];
    },
    options() {
      return {
        rewind: true,
        gap: '1.25rem',
        perPage: 2,
        breakpoints: {
          640: {
            perPage: 1,
            gap: '1rem',
          },
          480: {
            perPage: 1,
            gap: '1rem',
          },
        },
      };
    },
  },
  data() {
    return {
      slide: 0,
      toggler: false,
      RestaurantImageCaption: RestaurantImageCaption,
      modalImages: [],
      captionArray: [],
      slideIndex: 0,
      playerOptions: {
        autoplay: true,
        controls: true,
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
        },
      },
    };
  },
  components: {
    FsLightbox,
  },
  methods: {
    openImageModal(index) {
      this.toggler = !this.toggler;
      this.slideIndex = index;
    },
    ...mapActions({
      doFind: 'restaurant/view/doFind',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    getFirstWordPresenter(description) {
      if (this.isRTL) {
        return this.getFirstWord(description['ar']);
      } else {
        return this.getFirstWord(description['en']);
      }
    },
    getFirstWord(str) {
      let spaceIndex = str.indexOf(' ');
      return spaceIndex === -1
        ? str
        : str.substr(0, spaceIndex);
    },
    getSecondWordPresenter(description) {
      if (this.isRTL) {
        return this.getSecondWord(description['ar']);
      } else {
        return this.getSecondWord(description['en']);
      }
    },
    getSecondWord(str) {
      return str.split(/\s+/).slice(1, 2).toString();
    },
    removeFirstTwoWord(description) {
      let str = this.isRTL
        ? description['ar']
        : description['en'];
      str = str.split(/\s+/).slice(2, str.length);
      return str.join(' ');
    },
    namePresenter(value) {
      return this.isRTL ? value['ar'] : value['en'];
    },
  },
  async created() {
    await this.doFind(this.$route.params.id);
    console.log('record', this.record);
    // this.record = this.restaurants[this.$route.params.id]
    // console.log(this.$route.params.id)
    // const imagesNumber = this.record.images.length
    // this.captionArray = new Array(imagesNumber)
    // this.captionArray.fill(this.RestaurantImageCaption)
  },
};
